import dotenv from 'dotenv'
import uaMobile from "is-mobile";

dotenv.config();
const iGPendpoint = process.env.REACT_APP_APIURL;
const apiKey = process.env.REACT_APP_APIKEY;




async function OperatorSendRequest(endpoint, method, type, request, success, error, isAuth) {
    const user = JSON.parse(localStorage.getItem("account"));
    const headers = {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "X-API-KEY": apiKey
    }

    if (isAuth) {
        headers["Authorization"] = "Bearer " + user.token
    }

    const response = (type != "GET" ?

        await fetch(endpoint + method, {
            method: type,
            mode: "cors",
            headers,
            body: JSON.stringify(request)
        }) :
        await fetch(endpoint + method, {
            method: type,
            mode: "cors",
            headers
        })
    );


    try {


        if (response.status == 200) {

            const data = await response.json();

            if (data !== null) {
                success(data);
            } else {
                error("Empty Response");
            }
        } else if (response.status >= 400 && response.status < 500) {
            // Reject the Promise if the API returns a 400 error

            const errorData = await response.json();
            error(errorData);
        } else {
            error(await response.json());
        }
    } catch (e) {
        error(e);
    }
}



async function AccountSendRequest(endpoint, method, type, request, refresh, success, error) {

    let user = JSON.parse(localStorage.getItem("account"));

    if (user) {
        const response = (type !== "GET" ?

            await fetch(iGPendpoint + method, {
                method: type,
                mode: "cors",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": "Bearer " + user.token
                },
                body: JSON.stringify(request)
            }) :
            await fetch(endpoint + method, {
                method: type,
                mode: "cors",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": "Bearer " + user.token
                }
            })
        );


        if (response.status === 200) {

            const data = await response.json();

            if (data !== null)
                success(data);
            else
                error("Empty Response");
        }
        else if (response.status === 401) {

            LoginRefresh(user.token, user.refreshToken).then(
                (data) => {

                    user.token = data.token;
                    user.refreshToken = data.refreshToken;

                    localStorage.setItem("account", JSON.stringify(user));

                    refresh();
                },
                () => { error(response.status); }
            );
        }
        else {

            let err = await response.json();

            if (response.status === 400 && err === "Invalid Account Token")
                LoginRefresh(user.token, user.refreshToken).then(
                    (data) => {

                        user.token = data.token;
                        user.refreshToken = data.refreshToken;

                        localStorage.setItem("account", JSON.stringify(user));

                        refresh();
                    },
                    () => { error(response.status); }
                );
            else
                error(err);
        }
    }
}



// iGP Methods Call
export function Login(username, password) {

    return new Promise(
        async function (resolve, reject) {

            const response = await fetch(iGPendpoint + "accounts/login", {
                method: "POST",
                mode: "cors",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "X-API-KEY": apiKey
                },
                body: JSON.stringify({
                    "username": username,
                    "password": password
                })
            })

            if (response.status === 200) {

                const data = await response.json();

                if (data !== null)
                    resolve(data);
                else
                    reject("Empty Response");
            }
            else
                reject(await response.json());
        }
    );
}



export function LoginRefresh(token, refresh) {

    return new Promise(async function (resolve, reject) {

        const response = await fetch(iGPendpoint + "accounts/refresh", {
            method: "POST",
            mode: "cors",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            },
            body: JSON.stringify({ token: token, refreshToken: refresh })
        });


        if (response.status === 200) {

            const data = await response.json();

            if (data !== null)
                resolve(data);
            else
                reject("Generic Error");
        }
        else {

            localStorage.setItem('account', null);
            localStorage.removeItem('account');

            window.location.href = "/";
        }
    });
}



export function AddAccount(account) {

    return new Promise(
        function (resolve, reject) {
            OperatorSendRequest(iGPendpoint, "accounts", "POST", account, resolve, reject);
        }
    );
}



export function GetAccounts(id, type, parent) {
    return new Promise(
        function (resolve, reject) {
            OperatorSendRequest(iGPendpoint, "accounts" + (id === null ? "" : "/" + id) + (type === null ? "" : "/" + type) + (parent === null ? "" : "/" + parent), "GET", null, resolve, reject);
        }
    );
}



export function UpdateAccount(id, account) {

    return new Promise(
        function (resolve, reject) {
            OperatorSendRequest(iGPendpoint, "accounts" + (id === null ? "" : "/" + id), "PUT", account, resolve, reject);
        }
    );
}



export function GetAccountBalance(id) {

    return new Promise(
        function (resolve, reject) {
            OperatorSendRequest(iGPendpoint, "accounts/balance/" + id, "GET", null, resolve, reject);
        }
    );
}



export function Transfer(account, type, amount, currency, note) {

    if (type === 1) {

        return new Promise(
            function (resolve, reject) {
                OperatorSendRequest(iGPendpoint, "accounts/deposit", "POST", {
                    "username": account,
                    "amount": amount,
                    "currency": currency,
                    "note": note
                }, resolve, reject);
            }
        );
    }
    else {

        return new Promise(
            function (resolve, reject) {
                OperatorSendRequest(iGPendpoint, "accounts/withdraw", "POST", {
                    "username": account,
                    "amount": -amount,
                    "currency": currency,
                    "note": note
                }, resolve, reject);
            }
        );
    }
}



export function Balance() {

    return new Promise(
        function (resolve, reject) {
            AccountSendRequest(iGPendpoint, "accounts/balance", "GET", null, () => { Balance() }, resolve, reject);
        }
    );
}

export function GetUserDetails() {

    return new Promise(
        function (resolve, reject) {
            AccountSendRequest(iGPendpoint, "accounts/getuserdetails", "GET", null, () => { Balance() }, resolve, reject);
        }
    );
}



export function GetUrl(vendor, provider, request) {
    let url = ""
    if (vendor == null || vendor.length == 0) {
        url = "games/url?provider=" + provider +
            "&userID=" + request.userID +
            "&gameID=" + request.gameID +
            "&currency=" + request.currency +
            "&language=" + request.language +
            "&demo=" + (request.mode === "demo") +
            "&exit=" + request.exit +
            "&device=" + request.device

    } else {
        url = "games/url?vendor=" + vendor +
            "&provider=" + provider +
            "&userID=" + request.userID +
            "&gameID=" + request.gameID +
            "&currency=" + request.currency +
            "&language=" + request.language +
            "&demo=" + (request.mode == "demo") +
            "&exit=" + request.exit +
            "&device=" + request.device
    }

    if (request.isBonusPlay) {
        url = url + "&isBonusPlay=" + request.isBonusPlay
    }
    if (vendor == null || vendor.length == 0)
        return new Promise(
            function (resolve, reject) {
                OperatorSendRequest(iGPendpoint, url, "GET", null, resolve, reject);
            }
        );
    else
        return new Promise(
            function (resolve, reject) {
                OperatorSendRequest(iGPendpoint,
                    url, "GET", null, resolve, reject);
            }
        );
}



export function GetDepositUrl(provider, request) {

    return new Promise(
        function (resolve, reject) {
            OperatorSendRequest(iGPendpoint,
                `gateway/url?provider=${provider.name}&vendor=${provider.vendor}&userID=${request.userID}&currency=${request.currency}&language=${request.language}&device=${uaMobile() ? 'mobile' : 'desktop'}&amount=${request.amount}${request.additionalInfo ? "&additionalInfo=" + request.additionalInfo : ""}&getBonus=${request.getBonus}`, "GET", null, resolve, reject);
        }
    );
}

export function GetWithdrawUrl(request) {

    return new Promise(
        function (resolve, reject) {
            OperatorSendRequest(iGPendpoint,
                `gateway/withdraw`, "POST", request, resolve, reject);
        }
    );
}

export function RegisterPlayer(info) {

    return new Promise(
        function (resolve, reject) {
            OperatorSendRequest(iGPendpoint, "accounts/player", "POST", info, resolve, reject);
        }
    );
}



export function SendVerification(id, message) {

    return new Promise(
        function (resolve, reject) {
            OperatorSendRequest(iGPendpoint, "accounts/player/" + id + "/verification/send", "POST", message, resolve, reject);
        }
    );
}



export function CheckVerification(code) {

    return new Promise(
        function (resolve, reject) {
            OperatorSendRequest(iGPendpoint, "accounts/player/verification/check/" + code, "GET", null, resolve, reject);
        }
    );
}





export function ForgotPassword(email, link) {
    return new Promise(
        function (resolve, reject) {
            OperatorSendRequest(iGPendpoint, "accounts/player/forgot/password", "POST", { identifier: email, link: link }, resolve, reject);
        }
    );
}



export function GetGameList(query) {

    return new Promise(
        function (resolve, reject) {
            OperatorSendRequest(iGPendpoint, `games?provider=${query.provider}&name=${query.name}`, "GET", null, resolve, reject);
        }
    );
}

export function GetGameListBySearch(query) {

    return new Promise(
        function (resolve, reject) {
            OperatorSendRequest(iGPendpoint, `games/search?provider=${query.provider}&name=${query.name}`, "GET", null, resolve, reject);
        }
    );
}

export function GetGameListByCategory(query) {

    return new Promise(
        function (resolve, reject) {
            OperatorSendRequest(iGPendpoint, `games/categories/${query.name}`, "GET", null, resolve, reject);
        }
    );
}


export function getSortedGameList(query) {
    return new Promise(
        function (resolve, reject) {
            OperatorSendRequest(iGPendpoint, `games/sorted?provider=${query.provider}`, "GET", null, resolve, reject);
        }
    );
}


export function GameSearch(provider, name, type, order, tag, group, device) {
    return new Promise(
        function (resolve, reject) {
            OperatorSendRequest(iGPendpoint, "games/search", "POST", {
                provider,
                name,
                type,
                tag,
                order,
                group,
                device,
            }, resolve, reject);
        }
    );
}


export function GetGameSearch(query) {

    return new Promise((resolve, reject) => {
        OperatorSendRequest(
            iGPendpoint,
            `games/search?name=${query}`,
            'GET',
            null,
            resolve,
            reject
        );
    }
    );
}



export function GetTransactions(start, end) {

    return new Promise(
        function (resolve, reject) {
            AccountSendRequest(iGPendpoint, "transactions?start=" + start.toJSON() + "&end=" + end.toJSON(), "GET", null, () => { GetTransactions(start, end) }, resolve, reject);
        }
    );
}



export function GetHistory(start, end) {

    return new Promise(
        function (resolve, reject) {
            AccountSendRequest(iGPendpoint, "games/history?start=" + start.toJSON() + "&end=" + end.toJSON(), "GET", null, () => { GetHistory(start, end) }, resolve, reject);
        }
    );
}



export function CheckPassword(password) {

    return new Promise(
        function (resolve, reject) {
            AccountSendRequest(iGPendpoint, "accounts/player/check/password/" + password, "POST", null, () => { CheckPassword(password) }, resolve, reject);
        }
    );
}



export function EditAccount(request) {

    return new Promise(
        function (resolve, reject) {
            AccountSendRequest(iGPendpoint, "accounts", "PUT", request, () => { EditAccount(request) }, resolve, reject);
        }
    );
}



export function GetSections() {

    return new Promise(
        function (resolve, reject) {
            OperatorSendRequest(iGPendpoint, "cms/sections", "GET", null, resolve, reject);
        }
    );
}



export function GetWebSiteConfiguration(lang = null) {
    const language = lang ? `/${lang}` : '';
    return new Promise((resolve, reject) => {
        OperatorSendRequest(
            iGPendpoint,
            `configurations${language}`,
            "GET",
            null,
            resolve,
            reject
        );
    }
    );
}

export function GetPaymentConfiguration(type) {
    return new Promise((resolve, reject) => {
        OperatorSendRequest(
            iGPendpoint,
            `configurations/payments?type=${type}`,
            "GET",
            null,
            resolve,
            reject
        );
    }
    );
}



export function GetCategories() {

    return new Promise(
        function (resolve, reject) {
            OperatorSendRequest(iGPendpoint, "games/categories", "GET", null, resolve, reject);
        }
    );
}



export function GetPromotions() {

    return new Promise(
        function (resolve, reject) {
            OperatorSendRequest(iGPendpoint, "promotions", "GET", null, resolve, reject);
        }
    );
}



export function GetAffiliate(key) {

    return new Promise(
        function (resolve, reject) {
            OperatorSendRequest(iGPendpoint, "accounts/affiliate/key/" + key, "GET", null, resolve, reject);
        }
    );
}


export function GetPaymentHistory(userId, type) {
    let url = `payments/history/${userId}`
    if (type) {
        url = `payments/history/${userId}?type=${type}`

    }

    return new Promise(
        function (resolve, reject) {
            OperatorSendRequest(iGPendpoint, url, "GET", null, resolve, reject);
        }
    );
}


export function CancelPayment(transactionId, userId) {

    return new Promise(
        function (resolve, reject) {
            OperatorSendRequest(iGPendpoint, `payments/cancel/${transactionId}?userId=${userId}`, "DELETE", null, resolve, reject);
        }
    );
}


export function GetKYC(id) {
    return new Promise(function (resolve, reject) {
        OperatorSendRequest(
            iGPendpoint,
            "accounts/player/" + id + "/kyc",
            "GET",
            null,
            resolve,
            reject
        );
    });
}


export function SendKYC(id, documentType, fileName, file, notes) {
    return new Promise(
        function (resolve, reject) {
            OperatorSendRequest(iGPendpoint, "accounts/player/" + id + "/kyc", "POST", { documentType: documentType, fileName: fileName, file: file, notes: notes }, resolve, reject);
        }
    );
}



export function RemoveKYC(id,) {
    return new Promise(function (resolve, reject) {
        OperatorSendRequest(
            iGPendpoint,
            "accounts/player/" + id + "/kyc",
            "DELETE",
            null,
            resolve,
            reject
        );
    });
}

export function GetBonuses() {
    return new Promise(function (resolve, reject) {
        OperatorSendRequest(
            iGPendpoint,
            "promotions/bonus",
            "GET",
            null,
            resolve,
            reject,
            true
        );
    });
}

export function GetActiveBonus() {
    return new Promise(function (resolve, reject) {
        OperatorSendRequest(
            iGPendpoint,
            "promotions/bonus/getActive",
            "GET",
            null,
            resolve,
            reject,
            true
        );
    });
}


export function ClaimBonuses(bonusId) {
    return new Promise(function (resolve, reject) {
        OperatorSendRequest(
            iGPendpoint,
            `promotions/bonus/claim/${bonusId}`,
            "GET",
            null,
            resolve,
            reject,
            true
        );
    });
}


export function CancelBonuses(bonusId) {
    return new Promise(function (resolve, reject) {
        OperatorSendRequest(
            iGPendpoint,
            `promotions/bonus/cancel/${bonusId}`,
            "GET",
            null,
            resolve,
            reject,
            true
        );
    });
}