import React, { useState, createContext, useEffect } from 'react';

export const ConfigurationContext = createContext();

export const ConfigurationContextProvider = props => {
    const [configuration, setConfiguration] = useState([]);

    useEffect(() => {
        const config = props.configuration
        setConfiguration(config)
    }, [props.configuration])

    return (
        <ConfigurationContext.Provider value={{ configuration, setConfiguration }}>
            {props.children}
        </ConfigurationContext.Provider>
    );
}